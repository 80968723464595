<template>
  <div>
    <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.css">
    <link rel="stylesheet" href="https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.Default.css">
    <script type="application/javascript" src="https://unpkg.com/leaflet.markercluster@1.3.0/dist/leaflet.markercluster.js" />

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.2.3/leaflet.draw.css" />
    <!-- <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.2.3/leaflet.draw.js" onload="prepareEditor()" /> -->
 
    <v-card>
      <v-card-title class="headline">Custom map</v-card-title>
      <BadgeDetailsDialog ref="badgeDialog"/>
    <div class="map-container d-flex mb-8">
      <div id="map" ref="map" class="leaflet-map dynamic-content" style="margin-top:70px; height: 800px; width: 100%; z-index: 2;"/>
    </div>
    <p><a ref="download">Download gpx</a></p>
    <p v-if="marker">Coord: lng: {{marker.getLatLng().lng }}, lat: {{marker.getLatLng().lat }}  </p>
    <!--<script type="application/javascript" src="/js/three.min.js"></script>
    <script type="application/javascript" src="/js/panolens.min.js"></script>-->
  
 
    </v-card>
  </div>
</template>

<script>
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

window.L = L; // compatibility

import { EventBus } from '@/plugins/eventbus.js';
import togpx from '@/util/leaflet-togpx.js';
//import three from '@/util/three.min.js';
//import panolens from '@/util/panolens.min.js';
import { mapGetters } from "vuex";
//import * as L from 'leaflet';
//import { markercluster } from "leaflet.markercluster";
//import { leafletdraw } from "leaflet-draw";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";
 
var yx = L.latLng;

var xy = function(x, y) {
    if (L.Util.isArray(x)) {    // When doing xy([x, y]);  
        return yx(x[1], x[0]);
    }
    return yx(y, x);  // When doing xy(x, y);
};

export default {
  name: "AdminCustomMap",
  components: {
    BadgeDetailsDialog,
  },
  props: {
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      zoom: 11,
      routePolyDecoded: null,
      map: null,
      marker: null,
      polyline: null, 
      cluster: null,
      collectiveProgressMarker: null,
      editableLayers: null,
      markerIcon: window.L.icon({
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        //popupAnchor: [2, -40],
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
      }),
      collectiveProgressIcon: window.L.icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      }),
    };
  },
  async mounted() {
    await this.$helpers.loadExternalScript('https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.js');
    window.prepareEditor = this.prepareEditor; // so we can call it from onLoad
    this.$nextTick(() => {
      this.initMap();
    });
    
    window.EventBus = EventBus; // so we can access it from leaflet popup
    //this.$refs.badgeDialog.open({name: 'Mars', description: '360 degrees view from the Mars Curiosity', details_type: 'PANORAMA', details_img: '/images/content/mars-dune.png'});
    //this.$refs.badgeDialog.open({name: 'Some street view', description: 'Somewhere on this earth', details_type: 'STREET_VIEW', details_pano_id: 'FEpIJhSgOzoAAAQJOQCL3w'});
    //this.$refs.badgeDialog.open({name: 'Solar System', description: 'View the solar system', details_type: 'IMAGE', details_img: '/images/content/solar-system.png'});
  },
  methods: {
    prepareEditor() {
      console.log('prepareEditor.');
      this.initMap();
    },
    initMap() { 
      console.log('Loading custom map now.');
  
      this.$nextTick(() => {
        console.log('Preparing map.');
        if (!this.$refs.map) {
          return;  
        }
const data = {
                "img": "https://sodisp.imgix.net/content/events/ferrero-map-bg-beta.png",
                "width": 2018,
                "height": 1213,
                "min_zoom": -2,
                "max_zoom": 5,
                "default_zoom": 0
            };

        var w = data.width;
        var h = data.height;
        // calculate the edges of the image, in coordinate space

        this.map = window.L.map('map', {
            crs: window.L.CRS.Simple,
            minZoom:data.min_zoom,
            maxZoom: data.max_zoom,
            zoom: data.default_zoom,
            drawControl: true,
            //center: [0, -1*h],
            //center: [w/2, 1*(h/2)],
        });
        //var southWest = this.map.unproject([0, h], this.map.getMaxZoom() - 1);
        //var northEast = this.map.unproject([w, 0], this.map.getMaxZoom() - 1);
        //var bounds = new L.LatLngBounds(southWest, northEast);
        var bounds = [[0,0], [h, w]];
        this.map.setMaxBounds(bounds); 
        var image = window.L.imageOverlay(data.img, bounds).addTo(this.map);
        /*this.cluster = window.L.markerClusterGroup({
          showCoverageOnHover: false,
          chunkedLoading: true,
        });*/
 
        this.map.fitBounds(bounds); 

        //var earth = this.map.unproject([320, 395], this.map.getMaxZoom() - 1);
        //var earth = this.map.layerPointToLatLng([320, -320]);
        var sun      = xy(400, h-400.0);
        var earth      = xy(900, h-375.0);
        var jupiter      = xy(1170, h-400.0);
        console.log('Adding earth.', earth);
        /*window.L.marker(     earth, {icon: this.markerIcon}).addTo(this.map).bindPopup('Earth');
        window.L.circle(earth, {radius: 3}).addTo(this.map);
        window.L.polyline([sun, earth, jupiter]).addTo(this.map);
*/
        /*for (var i = 1; i < 100; i++) {
          //console.log('Adding marker.', i, 1/(i+1));
          var coord = this.midpoint(sun, earth, i/100);
          var marker = window.L.marker(coord, {icon: this.markerIcon});
          this.cluster.addLayer(marker);
        }*/
        //this.map.addLayer(this.cluster);
        this.addEditor();

        var self = this;
        // this.map.on('click', function(e) {
        //   if (self.marker){
        //     self.map.removeLayer(self.marker);
        //   }
        //   self.marker = window.L.marker(e.latlng, {icon: self.markerIcon}).addTo(self.map);
        //   console.log('click', e);
        // });
      })
    },
    
    addEditor() {
      if (this.map == null) {
        console.log('Waiting for map to initialize.');
        return;
      }
            
      // Initialise the FeatureGroup to store editable layers
      this.editableLayers = new window.L.FeatureGroup();
      this.map.addLayer(this.editableLayers);

      var drawPluginOptions = {
        position: 'topright',
        draw: {
          polygon: false,
          // disable toolbar item by setting it to false
          polyline: {
            shapeOptions: {
              color: '#f357a1',
              weight: 10
                }
          },
          circle: false, // Turns off this drawing tool
          rectangle: false,
          marker: false,
          },
        edit: {
          featureGroup: this.editableLayers, //REQUIRED!!
          remove: false
        }
      };

      // Initialise the draw control and pass it the FeatureGroup of editable layers
      console.log('window.L.Control.Draw', window.L.Control.Draw);
      var drawControl = new window.L.Control.Draw(drawPluginOptions);
      this.map.addControl(drawControl);

      var self = this;
        //console.log('prepare', leafletdraw.Event);
      this.map.on('draw:created', function(e) {
        var type = e.layerType,
          layer = e.layer;

        console.log('DRAWN', type, layer);
        self.editableLayers.addLayer(layer);
        if (type === 'polyline') {
          var latlngs = layer.getLatLngs().map(x => ({ y: 680 - x.lat, x: x.lng }));
          console.log('latlngs', togpx, latlngs);
          var gpx = togpx(self.editableLayers.toGeoJSON(), { });
          var convertedData = 'application/xml;charset=utf-8,' + encodeURIComponent(gpx);
          self.$refs.download.setAttribute('href', 'data:' + convertedData); 
          self.$refs.download.setAttribute('download', 'data.gpx');

        }
      });
return;
      this.routePolyDecoded = this.race.route == null ? null : PolylineUtil.decode(this.race.route);

        //this.polyline = this.$refs.poly.mapObject;
      window.L.polyline(this.routePolyDecoded, { color: 'white', weight: 8, stroke: true, fill: false }).addTo(this.map);
      this.polyline = window.L.polyline(this.routePolyDecoded, { color: '#008AFF', weight: 5, stroke: true, fill: false }).addTo(this.map);
      this.map.setMaxBounds(null); // reset to prevent clipping when course is changed
      this.map.fitBounds(this.polyline.getBounds().pad(.1 /* 10% */));
      this.map.setMaxBounds(this.polyline.getBounds().pad(.5 /* 50% */));
      this.ensureBadgesLoaded();
      this.loadedRaceId = this.race.id;
    },


    clear() {
      console.log('Clearing the map.');
      if (this.map) this.clearOverlays(this.map);
      //this.map = null;
    },
    
    midpoint(coord1, coord2, ratio) {
        return window.L.latLng(coord1.lat + (coord2.lat - coord1.lat) * ratio, coord1.lng + (coord2.lng - coord1.lng) * ratio);
    },

    clearOverlays: function(m) {
        for(const i in m._layers) {
            if(m._layers[i]._path != undefined) {
                try {
                    m.removeLayer(m._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + m._layers[i]);
                }
            }
        }
    },
    mapPanned(map) {
      //this.debugInfo = `Zoom: ${map.getZoom()}, bounds: ${ map.getBounds() }`;
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
  .full-dialog { width:100vw;height:100vh; }
  .fit-image { max-width:100vw;max-height:100vh; }
</style>

